import axios from "@/plugins/axios";

import folders from "./userdata/folders";
import files from "./userdata/files";
import cards from "./userdata/cards";
import hints from "./userdata/hints";

const modules = {
  folders,
  files,
  cards,
  hints
};

const state = {
	UserData: [],
	selectedItem: null,
	currentCardIndex: 0,
	currentHintIndex: 0,
	isModifyCardsMode: false,
};

const actions = {
	gotoModifyCards: ({ commit }) => {
		commit("gotoModifyCards");
	},
	gotoModifyItem: ({ commit }) => {
		commit("gotoModifyItem");
	},
	updateSelectedItem: ({ commit }, newItem) => {
		console.debug("updateSelectedItem has fired");
		commit("updateSelectedItem", newItem);

		/* whenever the user selects a new item, we want to assure that the 
    card modification interface is turned off */
		commit("gotoModifyItem");
	},
	getUserData: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get("userdata/folders/")
        .then((response) => {
          console.debug("API resolved - fetched user data", response.data);
  
          var userDataArray = [response.data];
  
          /* explicitly set root node for vue-treeselect component 
          this is done for later identification with the normalizer function */
          userDataArray[0].isRoot = true;
          
          commit("addUserData", userDataArray);
          resolve();
        })
        .catch((error) => {
          console.error("API error fetching user data", error);
          alert(error.response.data)
          reject();
        });
      }
    )
	},
  gotoNextCard: ({commit}) => {
    commit('gotoNextCard');
  },
  gotoPreviousCard: ({commit}) => {
    commit('gotoPreviousCard');
  },
  gotoNextHint: ({commit}) => {
    commit('gotoNextHint');
  },
  gotoPreviousHint: ({commit}) => {
    commit('gotoPreviousHint');
  },
};
const mutations = {
  gotoNextCard: (state) => {
    if (state.currentCardIndex == (state.selectedItem.cards.length-1)) {
      return;
    }
    else {
      state.currentHintIndex = 0;
      state.currentCardIndex++;
    }
  },
  gotoPreviousCard: (state) => {
    if (state.currentCardIndex == 0) {
      return;
    }
    else {
      state.currentHintIndex = 0;
      state.currentCardIndex--;
    }
  },
  gotoNextHint: (state) => {
    if (state.currentHintIndex == (state.selectedItem.cards[state.currentCardIndex].hints.length-1)) {
      return;
    }
    else {
      state.currentHintIndex++;
    }
  },
  gotoPreviousHint: (state) => {
    if (state.currentHintIndex == 0) {
      return;
    }
    else {
      state.currentHintIndex--;
    }
  },
	gotoModifyCards: (state) => {
		state.isModifyCardsMode = true;
	},
	gotoModifyItem: (state) => {
		state.isModifyCardsMode = false;

    // assure that the hint and card indexes are reset to avoid out of array indexing
    state.currentCardIndex = 0;
    state.currentHintIndex = 0;
	},

	updateSelectedItem: (state, payload) => {
		state.selectedItem = payload;
	},
	addUserData: (state, payload) => {
		state.UserData = payload;
	},
	addFolder: (state, newFolder) => {
		state.selectedItem.folders.push(newFolder);
	},
	addFile: (state, newFile) => {
		state.selectedItem.files.push(newFile);
	},
  createCard: (state, newCard) => {
    state.selectedItem.cards.push(newCard);
    state.currentCardIndex = (state.selectedItem.cards.length - 1);
  },
  deleteCard: (state) => {

    var temp_currentCardIndex = state.currentCardIndex;

    if ( temp_currentCardIndex == (state.selectedItem.cards.length - 1)) {
      // just to assure that we're not accessing the array out of bounds
      if (state.currentCardIndex != 0) {
        state.currentCardIndex--;
      }
    }
    state.selectedItem.cards.splice(temp_currentCardIndex, 1);
  },
  updateCard: (state, updatedCard) => {
    state.selectedItem.cards[state.currentCardIndex].content = updatedCard.content;
  },
  createHint: (state, newHint) => {
    state.selectedItem.cards[state.currentCardIndex].hints.push(newHint);
    state.currentHintIndex = (state.selectedItem.cards[state.currentCardIndex].hints.length - 1);
  },
  deleteHint: (state) => {

    var temp_currentHintIndex = state.currentHintIndex;

    if ( temp_currentHintIndex == (state.selectedItem.cards[state.currentCardIndex].hints.length - 1)) {
      // just to assure that we're not accessing the array out of bounds
      if (state.currentHintIndex != 0) {
        state.currentHintIndex--;
      }
    }
    state.selectedItem.cards[state.currentCardIndex].hints.splice(temp_currentHintIndex, 1);
  },
  updateHint: (state, data) => {
    state.selectedItem.cards[state.currentCardIndex].hints[state.currentHintIndex].content = data;
  }
};

const getters = {
  getSelectedItem: (state) => {
    return state.selectedItem;
  },
  getSelectedItemName: (state) => {
    return state.selectedItem.name;
  },
  getSelectedItemDescription: (state) => {
    return state.selectedItem.description;
  },
  getSelectedItemId: (state) => {
    return state.selectedItem.id;
  },
  getSelectedItemVisibility: (state) => {
    return state.selectedItem.visibility;
  },

	getCurrentCard: (state) => {
		if (
			state.selectedItem.isFile &&
			state.selectedItem.cards != null &&
			state.selectedItem.cards.length != 0
		) {
			return state.selectedItem.cards[state.currentCardIndex];
		} else {
			console.debug("No cards available");
      return null;
		}
	},


	getCurrentHint: (state, getters) => {
		if (
      state.selectedItem.isFile &&
			state.selectedItem.cards != null &&
			state.selectedItem.cards.length != 0 &&
			state.selectedItem.cards[state.currentCardIndex].hints != null &&
			state.selectedItem.cards[state.currentCardIndex].hints.length != 0
		) {
			return getters.getCurrentCard.hints[state.currentHintIndex];
		} else {
      console.debug("No hints available");
      return null;
		}
	},

  getMyUsername: (state) => {
    if (state.UserData[0] != undefined) {
      return state.UserData[0].me;
    }
    else {
      return null;
    }
  }
  
};

export default {
	state,
	mutations,
	actions,
	getters,
  modules,
};
