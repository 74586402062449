import axios from "@/plugins/axios";
import { LOAD_CHILDREN_OPTIONS } from "@riophae/vue-treeselect";

const state = {
	PublicData: [
		{
			isPublicRoot: true,
			users: {},
		},
	],
  selectedUserNode: null
};

const actions = {
	getPublicData: ({ commit }) => {
		commit("addPublicData");
	},
	getPublicUserFolders: ({ commit }, { action, parentNode, callback }) => {
		// once user node is opened, GET the public items associated to the user account
    //console.debug('LD_CHILDREN_OPTIONS, parentNode = ',JSON.parse(JSON.stringify(parentNode)));
    if (action === LOAD_CHILDREN_OPTIONS) {
			axios
				.get(`userdata/folders/${parentNode.username}/browse?type=`)
				.then((response) => {
					console.debug("API resolved - fetched user's public folders", response.data);

          var payload = {
            userNode: parentNode, 
            userData: response.data, 
            callback: callback
          };
          
					commit("getPublicUserFolders", payload);
				})
				.catch((error) => {
					console.error("API error fetching user's public folders", error);
          alert(error.response.data)
				});
		}
	},
	getUsers: ({ commit }) => {
		axios
			.get("userdata/users/")
			.then((response) => {
				console.debug("API resolved - fetched list of users", response.data);
        
        // this is for delayed branch loading with vue-treeselect component
        // this CAN'T be done via the normalizer function, because the normalizer() is 
        // not called after assigning the children property following the folder loading
        // (see getPublicUserFolders mutation). Doing it here assures that the users data is
        // loaded after opening the branch node
        response.data.forEach(element => {
          element.children = null;
        });

				commit("addPublicData", response.data);
			})
			.catch((error) => {
				console.error("API error fetching list of users", error);
        alert(error.response.data)
			});
	},
};
const mutations = {
	addPublicData: (state, payload) => {
		state.PublicData[0].users = payload;
	},
	getPublicUserFolders: (state, {userNode, userData, callback}) => {
    // add to state for tracking purposes
    state.selectedUserNode = userNode;

    // this loads the previously unloaded branch
    state.selectedUserNode.children =  [...userData.files, ...userData.folders];

    //console.debug('getPublicUserFolders after mutation = ',JSON.parse(JSON.stringify(userNode)));
    callback();
    
	},
};

const getters = {};

export default {
	state,
	mutations,
	actions,
	getters,
};
