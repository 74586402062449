import axios from "@/plugins/axios";

const state = {
  // websocket connection for seeing list of rooms and associated data
  roomListSocket: undefined,

  // for keeping track of when list of room data changes (format json)
  lastRoomList: 'none',

  // current list of rooms
  rooms: [],
}

const actions = {
  closeRoomListWs: ({commit, state}) => {
    if (state.roomListSocket != undefined) {
      state.roomListSocket.close();
      commit('closeRoomListWs');
    }
  },

  connectRoomListWs: ({ commit, state, dispatch }) => {
    console.debug("Starting connection to WS server...");

    state.roomListSocket = new WebSocket('wss://studyandplay.unistra.fr/ws/v1/lobby/?token=' + localStorage.getItem('JWT__access__token'));

    state.roomListSocket.onopen = (e) => {
      console.debug("Succesively connected to roomListWs...", e);
    }

    state.roomListSocket.onmessage = (e) => { 
      // only update room list if the last message has changed
      // *** this is just a quick fix, this is unreasonable when there are bunch of rooms
      if (state.lastRoomList != e.data) {
        console.debug("new room list received from ws", state.rooms)
        commit('updateRoomList', e.data)
      }
    }

    state.roomListSocket.onclose = (e) => {
      console.debug("roomListWs closed", e)
      dispatch('closeRoomListWs');
    }
  },

  createRoom: ({ dispatch }, roomInfo) => {
    return new Promise((resolve, reject) => {
      axios
        .post("gamehandler/rooms/",roomInfo)
        .then((response) => {
          console.debug("Succesively created new room", response.data)

          // this token is used for room connection 
          // as well as for vue-router navigation
          var roomToken = response.data.token;

          // i don't see an ID property on the response data, so i'll just save the token
          dispatch('updateRoomId', response.data.token);

          dispatch('connectPlayerGameWs', roomToken)
          .then(() => {
            resolve(roomToken);
          })
          .catch((error) => {
            alert(error.response.data)
            reject(error)
          })
        })
        .catch((error) => {
          console.error("API error creating new room", error);
          alert(error.response.data)
          reject();
        });
      }
    )
  },
}

const mutations = {
  closeRoomListWs: (state) => {
    state.roomListSocket = undefined;
  },
  updateRoomList: (state, roomList) => {
    state.rooms = JSON.parse(roomList);
    state.lastRoomList = roomList;
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}