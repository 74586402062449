import axios from "@/plugins/axios";
import constants from "@/plugins/constants";

const state = {};

const actions = {
	deleteFolder: ({ dispatch, getters }) => {
		axios
			.delete(`userdata/folders/${getters.getSelectedItemId}/`)
			.then((response) => {
				console.debug("API resolved - folder deleted", response.data);

        // now deselect deleted folder
				dispatch("updateSelectedItem", null);

        // fetch new data for the tree otherwise the deleted item(s) will be visible. 
        dispatch("getUserData");
			})
			.catch((error) => {
				console.error("API error during folder deletion", error);
        alert(error.response.data)
			});
	},

	updateFolder: ({ dispatch, getters }, {dataType, data}) => {
		var payload = {};

    switch (dataType) {
      case constants["ITEM_NAME"]:
        payload.name = data; // retrieved from form input
        payload.visibility = getters.getSelectedItemVisibility;
        break;

      case constants["ITEM_VISIBILITY"]:
        payload.name = getters.getSelectedItemName;
        payload.visibility = data; // retrieved from form input
        break;
		}

		axios
			.put(`userdata/folders/${getters.getSelectedItemId}/`, payload)
			.then((response) => {
				console.debug("API resolved - item updated", response.data);

				/* now update selected folder with changes. First we need to get the information
        that hasn't changed from the currently selected item */
				payload = getters.getSelectedItem;
				payload.name = response.data.name;
				payload.visibility = response.data.visibility;
				payload.share_code = response.data.share_code;

				dispatch("updateSelectedItem", payload);
			})
			.catch((error) => {
				console.error("API error during folder modification", error);
        alert(error.response.data.name)
			});
	},

	createFolder: ({ commit }, newFolder) => {
		axios
			.post("userdata/folders/", newFolder)
			.then((response) => {
				console.debug("API resolved - folder created", response.data);
				commit("addFolder", response.data);
			})
			.catch((error) => {
				console.error("API error during folder creation", error);
        alert(error.response.data)
			});
	},
};

const mutations = {};
const getters = {};

export default {
	state,
	mutations,
	actions,
	getters,
};
