import constants from '@/plugins/constants';
import router from '@/router';
import axios from '@/plugins/axios';
import dummyRoom from '@/data/dummyRoom';
var _isEmpty = require('lodash/isEmpty');

const state = {
  playerGameSocket: undefined,
  // initialization reactive data so no errors occur on rendering
  // this data object is also used for testing without connecting to actual game Ws
  joinedRoom: dummyRoom,
  createdRoom: {},
  currentCard: {
    content: 'No card available',
    hints: [{content:'No hint available'}],
  },
  roomId: 0,
}

const actions = {
  updateRoomId: ({commit}, roomId) => {
    commit('updateRoomId',roomId)
  },
  connectPlayerGameWs: ({ state, dispatch }, roomToken) => {
    return new Promise((resolve, reject) => {
      // create websocket
      state.playerGameSocket = new WebSocket(`wss://studyandplay.unistra.fr/ws/v1/player/${roomToken}?token=` + localStorage.getItem('JWT__access__token'))
  
      state.playerGameSocket.onopen = (e) => {
        console.debug('Successively connected to playerGameWs', e)
        resolve();
      }

      state.playerGameSocket.onmessage = (e) => {
        var parsedPlayerGameSocketMsg = JSON.parse(e.data);
        //console.debug('parsed JSON playerGameSocket msg', parsedPlayerGameSocketMsg)

        // error status_code handling...
        if ((parsedPlayerGameSocketMsg.name == 'error_message')) {
          alert(constants[`${parsedPlayerGameSocketMsg.error_code}`])
        }

        // send new card for CCC (this is a new card WS event)
        else if (parsedPlayerGameSocketMsg.name === 'ccc_new_card') {
          console.debug('ccc_new_card has fired')
          dispatch('updateCurrentCard', parsedPlayerGameSocketMsg.card);
        }     

        else if (parsedPlayerGameSocketMsg.name === 'game_update') {
          // for handling what to show on frontend
          switch(parsedPlayerGameSocketMsg.room.status) {
            case constants['ROOM_PREGAME_LOBBY']:
              break;
            case constants['ROOM_LOADING_GAME']:
              console.debug('Game is loading...')
              break;
            case constants['ROOM_STARTING_GAME']:
              console.debug('Game is starting! COUNTDOWN...', parsedPlayerGameSocketMsg.timer)
              if (router.currentRoute.path != '/game') {
                router.push('/game');
              }
              break;       
            case constants['ROOM_PLAYING_GAME']:
              break;       
            case constants['ROOM_TRANSITION_GAME']:
              console.debug('Round transisition! COUNTDOWN...', parsedPlayerGameSocketMsg.timer)
              break;       
            case constants['ROOM_WIN_GAME']:
              break;       
            case constants['ROOM_END_GAME']:
              router.push('/rooms');
              break; 
            default:
              console.error('Invalid playerGameWs room status');
          }
          dispatch('updateJoinedRoom', parsedPlayerGameSocketMsg);
        }
        else {
          console.error('Unkown websocket event name');
        }
      }
  
      state.playerGameSocket.onclose = (e) => {
        console.debug("playerGameSocket closed", e)
        dispatch('closePlayerGameWs');
        if (router.currentRoute.path === '/game') {
          router.push('/rooms');
        }
      }
      state.playerGameSocket.onerror = (e) => {
        console.debug('playerGameWs error', e)
        reject(e);
        router.push('/rooms');
      }
    })
  },

  closePlayerGameWs: ({commit, state}) => {
    if (state.playerGameSocket != undefined) {
      state.playerGameSocket.close();
      commit('closePlayerGameWs');
    }
  },

  changePlayerTeam: ({ state }) => {
    state.playerGameSocket.send(JSON.stringify({
      'event_name': 'switch_team'
    }))
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000)
    })
  },

  changePlayerStatus: ({state} ) => {
    state.playerGameSocket.send(JSON.stringify({
      'event_name': 'player_ready'
    }))

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000)
    })  
  },

  updateCurrentCard: ({commit}, payload) => {
    commit('updateCurrentCard', payload);
  },
  updateJoinedRoom: ({commit}, payload) => {
    commit('updateJoinedRoom', payload);
  },

  startGameWs: ({ state }, payload) => {

    console.debug("startGameWs options = ",payload);

    /*
    'tour_duration': payload.tour_duration,
    'mode': payload.mode,
    'max_points':payload.max_points
    */
    axios
      .put(`gamehandler/rooms/${state.roomId}/`, {
        'tour_duration': payload.tour_duration,
        'mode': payload.mode,
        'win_points':payload.max_points
      })
      .then(() => {
        state.playerGameSocket.send(JSON.stringify({
          'event_name': 'start_game',
          'files': payload.file_ids_selected,
        }))
      })
      .catch((error) => {
        console.error('Error starting game...',error)
        alert('Error starting game')
      })

  },

  nextCard: ({state}) => {
    console.debug('skip card requested from ws...');

    state.playerGameSocket.send(JSON.stringify({
      'event_name': 'skip_card'
    }))
  },
  correctGuess: ({state}) => {
    console.debug('correct guess requested from ws...')

    state.playerGameSocket.send(JSON.stringify({
      'event_name': 'correct_card'
    }))
  },

}

const mutations = {
  updateCurrentCard: (state, payload) => {
    state.currentCard = payload;
  },
  closePlayerGameWs: (state) => {
    state.playerGameSocket = undefined;
  },
  updateJoinedRoom: (state, newRoomData) => {
    state.joinedRoom = newRoomData;
  },
  updateRoomId: (state, roomId) => {
    state.roomId = roomId;
  }
}

const getters = {
  getPlayersInRoom: (state) => {
    var playersArray = [];

    if (_isEmpty(state.joinedRoom) == false) {
      // assign RED team values for b-table component
      if (_isEmpty(state.joinedRoom.teams['1'].players == false)) {
        state.joinedRoom.teams['1'].players.forEach(function(item) {
          item.team = constants['RED'];
          playersArray.push(item);
        })
      }
  
      // assign BLUE team values for b-table component
      if (_isEmpty(state.joinedRoom.teams['2'].players == false)) {
        state.joinedRoom.teams['2'].players.forEach(function(item) {
          item.team = constants['BLUE'];
          playersArray.push(item);
        })
      } 
  
      if (_isEmpty(playersArray) == true) {
        return null;
      }
      else {
        return playersArray;
      }
    }
    
    else {
      return null;
    }
  },

  checkPlayersStatus: (state, getters) => {
    var allPlayersReady = true;

    if((_isEmpty(state.joinedRoom) == false)) {
      // loop through each player in the specified room and 
      // check their ready/not ready status
      getters.getPlayersInRoom.forEach(
        player => {
          // if any of the players are not ready then exit loop
          if (player.status === constants['PLAYER_NOT_READY']) {
            allPlayersReady = false;
            return;
          }
        }
      )
      // result after looping through players array
      //console.debug(`all players ready: ${allPlayersReady}`)
      return allPlayersReady;
    }
    else {
      return false;
    }

  },

  getRedPlayers: (state, getters) => {
    var players = [];

    if((_isEmpty(state.joinedRoom) == false)) {
      getters.getPlayersInRoom.forEach((player) => {
        if (player.team === constants['RED']) {
          players.push(player);
        }
      })

      return players;
    }

    else return players;
  },
  getBluePlayers: (state, getters) => {
    var players = [];

    if((_isEmpty(state.joinedRoom) == false)) {
      getters.getPlayersInRoom.forEach((player) => {
        if (player.team === constants['BLUE']) {
          players.push(player);
        }
      })

      return players;
    }

    else return players;
  },



  getRoomHost: (state) => {
    if (_isEmpty(state.joinedRoom) == false) {
      return state.joinedRoom.room.host;
    }
    else {
      return null
    }
  },

  getRoomStatus: (state) => {
    if (_isEmpty(state.joinedRoom) == false) {
      return state.joinedRoom.room.status;
    }
    else {
      return null
    }
  },

  getGameMode: (state) => {
    if (_isEmpty(state.joinedRoom) == false) {
      return state.joinedRoom.room.mode;
    }
    else {
      return null
    }
  },
  getWinPoints: (state) => {
    if (_isEmpty(state.joinedRoom) == false) {
      return state.joinedRoom.room.win_points;
    }
    else {
      return null
    }
  },
  getWinningTeam: (state, getters) => {
    if (_isEmpty(state.joinedRoom) == false) {
      if (getters.getGameMode === constants['TNT']) {
        if (getters.getCurrentPoints[constants['RED']].points === 0) {
          return constants['BLUE'];
        }
        else if (getters.getCurrentPoints[constants['BLUE']].points === 0) {
          return constants['RED'];
        }
        else return null;
      }
    }
    else {
      return null
    }
  },
  getCurrentClueGiver: (state, getters) => {
    var currentClueGiver = {
      name: '---',
      team: constants['RED']
    };

    if (_isEmpty(state.joinedRoom) == false) {
      getters.getPlayersInRoom.forEach((player) => {
        if (player.status == constants['PLAYER_CLUE_GIVING']) {
          currentClueGiver.name = player.username;
          currentClueGiver.team = player.team;
          return;
        }
      })
      return currentClueGiver;
    }
    else {
      return currentClueGiver;
    }
  },
  getCurrentGuessingTeam: (state) => {
    if (_isEmpty(state.joinedRoom) == false) {
      if (state.joinedRoom.teams['1'].status === constants['TEAM_PLAYING']) {
        return constants['RED'];
      }
      else if (state.joinedRoom.teams['2'].status === constants['TEAM_PLAYING']) {
        return constants['BLUE'];
      }
      else {
        // neither team is guessing in this case
        return null;
      }
    }
    else {
      return null
    }
  },
  getMyTeam: (state, getters) => {
    var myTeam = null;

    if((_isEmpty(state.joinedRoom) == false)) {
      getters.getPlayersInRoom.forEach((player) => {
        if (player.username === getters.getMyUsername) {
          switch(player.team) {
            case constants['RED']:
              myTeam = constants['RED'];
              break;
            case constants['BLUE']:
              myTeam = constants['BLUE'];
              break;  
          }
          return;
        }
      })
      return myTeam;
    }

    else return myTeam;

  },
  getCurrentTime: (state) => {
    var timer = {
      minute: 0,
      second: 0,
    };

    if (_isEmpty(state.joinedRoom) == false) {
      // some magic to convert seconds from WS to minutes/seconds for displaying
      var convertedSecondsDate = new Date(state.joinedRoom.timer*1000).toISOString().substr(11, 8);
      // convertedSecondsDate is a string in format XX:XX:XX

      var minutes = parseInt(convertedSecondsDate.substr(3,2));
      var seconds = parseInt(convertedSecondsDate.substr(6,2));
      //console.debug('convertedSecondsDate',convertedSecondsDate);

      timer.second = seconds;
      timer.minute = minutes;

      return timer;
    }
    else {
      return timer;
    }
  },
  getCurrentPoints: (state) => {
    var team = [
      {
        color: constants['RED'],
        points: 0
      },
      {
        color: constants['BLUE'],
        points: 0
      }
    ];

    if (_isEmpty(state.joinedRoom) == false) {

      team[0].points = state.joinedRoom.teams['1'].score;
      team[1].points = state.joinedRoom.teams['2'].score;

      return team;
    }
    else {
      return team;
    }
  },
}





const modules = {
}

export default {
  state,
  actions,
  mutations,
  getters,
  modules
}