<template>
  <div id="app" v-bind:class="appThemeClass">
    <div class= "app-background" :style="appBackgroundStyle">
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <router-view></router-view>

      <Devbar></Devbar>
    </div>
  </div>
</template>

<script>

import Devbar from '@/components/Devbar.vue'
import { mapState } from 'vuex';

export default {
  data() {
    return {
      // fixed bgTransparency for dark mode is roughly 0.8
      bgTransparencyColor: 0.3,
      bgTransparencyDarkMode: 0.7,
    }
  },

  components: {
    Devbar,
  },
  methods: {
  },
  computed: {
    ...mapState({
      isDarkTheme: state => state.settings.isDarkTheme,
      backgroundImg: state => state.settings.backgroundImg,
      backgroundColor: state => state.settings.backgroundColor,
    }),
    appThemeClass() {
      return {
        'theme-dark': this.isDarkTheme,
        'theme-default': !this.isDarkTheme
      }
    },
    appBackgroundStyle() {
      var bgTransparency;

      if (this.isDarkTheme) {
        bgTransparency = this.bgTransparencyDarkMode;
      }
      else {
        bgTransparency = this.bgTransparencyColor;
      }
      return {
        '--bgColor': `rgba(${this.backgroundColor.r}, ${this.backgroundColor.g}, ${this.backgroundColor.b}, ${bgTransparency})`,
        '--bgImg': `url(${this.backgroundImg})`,
      }
    }
  }
}
</script>

<style>

</style>
