import axios from "@/plugins/axios";
import constants from "@/plugins/constants";

const state = {};
const actions = {
  deleteFile: ({ dispatch, getters }) => {
		axios
			.delete(`userdata/files/${getters.getSelectedItemId}/`)
			.then((response) => {
				console.debug("API resolved - file deleted", response.data);

        // now deselect deleted file
				dispatch("updateSelectedItem", null);

        // fetch new data for the tree otherwise the deleted item(s) will be visible. 
        dispatch("getUserData");
			})
			.catch((error) => {
				console.error("API error during file deletion", error);
        alert(error.response.data)
			});
	},

  updateFile: ({ dispatch, getters }, {dataType, data}) => {
		var payload = {};

    switch (dataType) {
      case constants["ITEM_NAME"]:
        payload.name = data; // retrieved from form input
        payload.description = getters.getSelectedItemDescription;
        payload.visibility = getters.getSelectedItemVisibility;
        break;
      
      case constants["ITEM_DESCRIPTION"]:
        payload.name = getters.getSelectedItemName;
        payload.description = data; // retrieved from form input
        payload.visibility = getters.getSelectedItemVisibility;
        break;
      
      case constants["ITEM_VISIBILITY"]:
        payload.name = getters.getSelectedItemName;
        payload.description = getters.getSelectedItemDescription;
        payload.visibility = data; // retrieved from form input
        break;
		}

		axios
			.put(`userdata/files/${getters.getSelectedItemId}/`, payload)
			.then((response) => {
				console.debug("API resolved - file updated", response.data);

				/* now update selected file with changes. First we need to get the information
        that hasn't changed from the currently selected item */
				payload = getters.getSelectedItem;
        payload.name = response.data.name;
        payload.description = response.data.description;
        payload.visibility = response.data.visibility;

				dispatch("updateSelectedItem", payload);
			})
			.catch((error) => {
				console.error("API error during file modification", error);
        alert(error.response.data.name)
			});
	},

	createFile: ({ commit }, newFile) => {
		axios
			.post("userdata/files/", newFile)
			.then((response) => {
				console.debug("API resolved - file created", response.data);
				commit("addFile", response.data);
			})
			.catch((error) => {
				console.error("API error during file creation", error);
        alert(error.response.data)
			});
	},

};

const mutations = {};
const getters = {};

export default {
	state,
	mutations,
	actions,
	getters,
};
