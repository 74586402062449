import Vue from 'vue'
import Vuex from 'vuex'

// contains everything for playerGameWs
import game from './modules/game'

// contains everything for roomListWs
import room from './modules/room'

import settings from './modules/settings'
import userdata from './modules/userdata'
import publicdata from './modules/publicdata'
import pregame from './modules/pregame'
import importation from './modules/importation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    game,
    room,
    settings,
    userdata,
    publicdata,
    pregame,
    importation,
  }
})