const constants = {
  // game specific
  ARCADE: 1,
  TNT: 0,
  RED: 0,
  BLUE: 1,

  // room specific
  PUBLIC_ROOM : true,
  PRIVATE_ROOM: false,

  // player status
  PLAYER_NOT_READY: 0,
  PLAYER_READY : 1,
  PLAYER_CLUE_GIVING: 2,

  // team status
  TEAM_WAITING: 0,
  TEAM_PLAYING: 1,

  // Game/Room status
  ROOM_PREGAME_LOBBY: 0,   // players waiting in lobby pregame
  ROOM_LOADING_GAME: 1,    // backend is loading cards and setting up game
  ROOM_STARTING_GAME: 2,   // 5...4...3... countdown to start game (game page)
  ROOM_PLAYING_GAME: 3,    // actually playing game
  ROOM_TRANSITION_GAME: 4, // transition time between rounds
  ROOM_WIN_GAME: 5,        // game is over (winning team)
  ROOM_END_GAME: -1,       // game is over and room has been "destroyed"


  // old dummy data stats
  ROOM_NOT_STARTED: 0,
  ROOM_STARTED: 1,
  GAME_STARTED: 2,
  ROUND: 0,
  ROUND_OVER: 1,
  GAME_OVER : 2,


  // File constants
  PUBLIC_ROOT: 'pr',
  USER_ROOT: 'ur',
  USER: 'u',
  ROOT: 'r',
  FOLDER: 'f',
  FILE: 'i',
  CARD: 'c',
  HINT: 'h',
  PRIVATE_FOLDER: 0,
  PUBLIC_FOLDER: 1,
  SHARED_FOLDER: 2,
  PUBLIC_FILE: true,
  PRIVATE_FILE: false,
  ITEM_NAME: 0,
  ITEM_VISIBILITY: 1,
  ITEM_DESCRIPTION: 2,


  // Websocket specific
  WS_CLOSED : 3,

  // WebSocket Errors
  3100: "Room token missing in request",
  3101: "Room not existing",
  3102: "Room has expired (game finished, status == -1)",
  3103: "Room is private and access_code missing in request",
  3104: "Room is private and access_code not matching",
  3105: "Room is full",
  3106: "Cant join game when status != Lobby",
  3107: "Cant change team when status != Lobby",
  3108: "Other team is full, cant change team",
  3109: "Cant change ready state when status != Lobby",
  3110: "Cant start game when status != Lobby",
  3111: "team min players condition not satisfied (start game)",
  3112: "Not all players are ready (start game)",
  3113: "At least 1 file is needed to start the game (start game)",

  // Callback errors:
  3150: "Unknown error, can't process request.",

  // Event codes :
  3200: "Player quit event is not successful",
  3201: 'Player quit event is successful',

};



export default constants;