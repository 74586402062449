<template>
  <div>
    <select v-model="$i18n.locale" class="locale-changer">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data () {
    return { langs: ['fr', 'en'] }
  }
}
</script>