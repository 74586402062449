//import axios from "@/plugins/axios";
var _isEmpty = require('lodash/isEmpty');

const state = {
  selectedUserImportItem: null,
  selectedPublicImportItem: null,
};

const actions = {
  updateSelectedUserImportItem: ({commit}, item) => {
    commit('updateSelectedUserImportItem', item);
  },
  updateSelectedPublicImportItem: ({commit}, item) => {
    commit('updateSelectedPublicImportItem', item);
  },
  clearSelectedImportItems: ({commit}) => {
    commit('clearSelectedImportItems');
  },
};
const mutations = {
  updateSelectedUserImportItem: (state, item) => {
    state.selectedUserImportItem = item;
  },
  updateSelectedPublicImportItem: (state, item) => {
    state.selectedPublicImportItem = item;
  },
  clearSelectedImportItem: (state) => {
    state.selectedPublicImportItem = null;
    state.selectedUserImportItem = null;
  }
};

const getters = {
  isImportFileSelected: (state) => {
    if ( _isEmpty(state.selectedUserImportItem) && _isEmpty(state.selectedPublicImportItem) ) {
      return false;
    }
    else {
      return true;
    }
  }
};

export default {
	state,
	mutations,
	actions,
	getters,
};
