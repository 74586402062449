<template>
  <div class='theme-checkbox'>
    <b-form-checkbox
      id="darkmode"
      v-model="status"
      name="darkmode"
      value="dark-on"
      unchecked-value="dark-off"
      >
      <span class="theme-checkbox-text mx-n2">Dark</span>
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: 'dark-on',
    }
  },
}
</script>