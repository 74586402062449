import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/play',
      name: 'Play',
      component: () => import('@/views/Play.vue'),
    },
    {
      path: '/rooms',
      name: 'Rooms',
      component: () => import('@/views/Rooms.vue'),
    },
    {
      path: '/howto',
      name: 'Rules',
      component: () => import('@/views/Howto.vue'),
    },
    {
      // now the pregame room route will contain the room's ID
      // we can then access this value via this.$route.params.roomid
      path: '/pregame/:roomid',
      name: 'Pregame',
      component: () => import('@/views/Pregame.vue'),
    },
    {
      path: '/game',
      name: 'Game',
      component: () => import('@/views/Game.vue'),
    },
    {
      path: '/files',
      name: 'Files',
      component: () => import('@/views/Files.vue'),
    },
    {
      path: '/me',
      name: 'Me',
      component: () => import('@/views/Me.vue'),
    },
    {
      path: '/public',
      name: 'Public',
      component: () => import('@/views/Public.vue'),
    },
    {
      path: '/import/:id',
      name: 'Import',
      component: () => import('@/views/Import.vue'),
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
    },
    {
      path: '/auth/cas/logout',
      name: 'cas_authentication_logout',
    },
  ]
})