//import axios from "@/plugins/axios";
var _isEmpty = require('lodash/isEmpty');

/* 

this module takes care of all the pregame selection
duration, mode, files selected, etc.

once all the cards are selected, this module groups the selectedUserItems.cards
and the selectedPublicItems.cards together into a single array of cards (unshuffled))

The game module will then copy this array of cards and shuffle them  together

the selectedUserItems and selectedPublicItems variables are used in conjunction with the
vue-treeselect component (holds the currently selected selected tree values)

*/

const state = {
  selectedUserItems: [],
  selectedPublicItems: [],
  selectedCards: [],
  selectedFileIds: [],
};

const actions = {
  updateSelectedUserItems: ({commit}, items) => {
    commit('updateSelectedUserItems', items);
  },
  updateSelectedPublicItems: ({commit}, items) => {
    commit('updateSelectedPublicItems', items);
  },
  updateSelectedFileIds: ({commit}) => {
    commit('updateSelectedFileIds');
  },
  updateSelectedCards: ({commit}) => {
    commit('updateSelectedCards');
  },
  clearSelectedItems: ({commit}) => {
    commit('clearSelectedItems');
  },
};
const mutations = {
  updateSelectedUserItems: (state, items) => {
    state.selectedUserItems = items;
  },
  updateSelectedPublicItems: (state, items) => {
    console.debug('updateSelectedPublicItems', items);
    state.selectedPublicItems = items;
  },


  updateSelectedFileIds: (state) => {
    var groupedItems = [...state.selectedUserItems, ...state.selectedPublicItems];
    var groupedFileIds = groupedItems.flatMap( (element) => {
      // this makes sure that empty files (files with no cards) 
      // are not added to the list of selected files
      if (_isEmpty(element.cards)) {
        return [];
      }
      else {
        return element.id;
      }
    });
    state.selectedFileIds = groupedFileIds;
  },


  updateSelectedCards: (state) => {
    var groupedItems = [...state.selectedUserItems, ...state.selectedPublicItems];
    var groupedCards = groupedItems.flatMap( (element) => {
      // this makes sure that empty files (files with no cards) are not added to the
      // list of game cards
      if (_isEmpty(element.cards)) {
        return [];
      }
      else {
        return element.cards;
      }
    });
    state.selectedCards = groupedCards;
  },
  clearSelectedItems: (state) => {
    state.selectedPublicItems = [];
    state.selectedUserItems = [];
  }
};

const getters = {
  isFileSelected: (state) => {
    if ( _isEmpty(state.selectedUserItems) && _isEmpty(state.selectedPublicItems) ) {
      return false;
    }
    else {
      return true;
    }
  }
};

export default {
	state,
	mutations,
	actions,
	getters,
};
