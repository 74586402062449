import axios from "@/plugins/axios";

const state = {};
const actions = {
  createCard: ({commit}, newCard) => {
    axios
      .post("userdata/cards/", newCard)
      .then((response) => {
        console.debug("API resolved - created card", response.data);
        commit('createCard', response.data)
      })
      .catch((error) => {
				console.error("API error creating card", error);
        alert(error.response.data)
      });
  },
  deleteCard: ({commit, getters}) => {
    axios
      .delete(`userdata/cards/${getters.getCurrentCard.id}/`)
      .then((response) => {
        console.debug("API resolved - card deleted", response.data);
        commit('deleteCard');
      })
      .catch((error) => {
        console.error("API error deleting card", error)
        alert(error.response.data)
      })
  },
  updateCard: ({commit, getters}, data) => {
    axios
      .put(`userdata/cards/${getters.getCurrentCard.id}/`, data)
      .then((response) => {
        console.debug("API resolved - card updated", response.data);
        commit('updateCard', response.data);
      })
      .catch((error) => {
        console.error("API error updating card", error)
        alert(error.response.data.content)
      })
  },
};
const mutations = {};
const getters = {};

export default {
	state,
	mutations,
	actions,
	getters,
};
