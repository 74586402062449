import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/scss/index.scss'

import router from './router'
import store from './store'
import i18n from './i18n'

import TitleMixin from './mixins/TitleMixin'
import constPlugin from "@/plugins/constPlugin";

import Vue from 'vue'

import axios from '@/plugins/axios';
import VueAxios from 'vue-axios';
import App from './App.vue'

Vue.use(VueAxios, axios);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.mixin(TitleMixin)
Vue.use(constPlugin);

import Cas from 'vue-cas-authentication';

const CasOptions = {
  router: router,
  axios: axios,
  options: {
    appIsAllAuth: true,
    authCasLogoutUrl: 'cas_authentication_logout',
    jwtServerUrl: 'https://studyandplay.unistra.fr/api/v1/authentication',
    serverCAS: 'https://cas.unistra.fr',
  },
};

Vue.use(Cas, CasOptions);

Vue.config.productionTip = false

new Vue({
 axios,
 router,
 store,
 i18n,
 render: h => h(App)
}).$mount('#app')
