import axios from "@/plugins/axios";

const state = {};
const actions = {
  createHint: ({commit}, newHint) => {
    axios
      .post("userdata/hints/", newHint)
      .then((response) => {
        console.debug("API resolved - created hint", response.data);
        commit('createHint', response.data)
      })
      .catch((error) => {
				console.error("API error creating hint", error);
        alert(error.response.data)
      });
  },
  deleteHint: ({commit, getters}) => {
    axios
      .delete(`userdata/hints/${getters.getCurrentHint.id}/`)
      .then((response) => {
        console.debug("API resolved - hint deleted", response.data);
        commit('deleteHint');
      })
      .catch((error) => {
        console.error("API error deleting hint", error)
        alert(error.response.data)
      })
  },
  updateHint: ({commit, getters}, data) => {
    axios
      .put(`userdata/hints/${getters.getCurrentHint.id}/`, data)
      .then((response) => {
        console.debug("API resolved - hint updated", response.data);
        commit('updateHint', response.data.content);
      })
      .catch((error) => {
        console.error("API error updating hint", error)
        alert(error.response.data.content)
      })
  },
};
const mutations = {};
const getters = {};

export default {
	state,
	mutations,
	actions,
	getters,
};
