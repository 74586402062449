<template>
  <div class="devbar-container d-flex flex-column" v-if="devMode">
    
    <!-- CHANGE LANGUAGE -->
    <div class="p-1">
      <LocaleChanger></LocaleChanger>
    </div>

    <!-- CHANGE DARK/DEFAULT THEME -->
    <div class="p-1">
      <ThemeCheckbox @change.native="checkDarkTheme"></ThemeCheckbox>
    </div>

    <!-- CHANGE GAME MODE -->
    <b-form-spinbutton
      class=""
      wrap
      size="sm"
      :class="isDarkTheme ? ['bg-dark', 'text-light'] : ['bg-light', 'text-dark']"
      v-model="gameMode"
      vertical
      @change="changeGameMode"
      min="0" 
      max="4" 
      id="gameMode" 
      type="number">
    </b-form-spinbutton>

  </div>
</template>

<script>

import LocaleChanger from '@/components/LocaleChanger.vue'
import ThemeCheckbox from '@/components/ThemeCheckbox.vue'
import { mapState, mapActions } from 'vuex';


export default {
	components: {
    LocaleChanger,
    ThemeCheckbox,
  },
  data() {
    return {
      gameMode: 0,
    }
  },
  methods: {
    changeGameMode() {
      var newJoinedRoom = this.joinedRoom;

      switch(this.gameMode) {

        // is CCC
        case 0:
          newJoinedRoom.teams['1'].players['0'].status = this.$getConst('PLAYER_CLUE_GIVING');
          newJoinedRoom.teams['1'].players['1'].status = this.$getConst('PLAYER_READY');
          newJoinedRoom.teams['2'].players['0'].status = this.$getConst('PLAYER_READY');
          newJoinedRoom.teams['2'].players['1'].status = this.$getConst('PLAYER_READY');

          newJoinedRoom.room.status = this.$getConst('ROOM_PLAYING_GAME');

          newJoinedRoom.teams['1'].status = this.$getConst('TEAM_PLAYING');
          newJoinedRoom.teams['2'].status = this.$getConst('TEAM_WAITING');

          newJoinedRoom.teams['1'].score = 5;
          break;

        // is not CCC on CCC's team
        case 1:
          newJoinedRoom.teams['1'].players['0'].status = this.$getConst('PLAYER_READY');
          newJoinedRoom.teams['1'].players['1'].status = this.$getConst('PLAYER_CLUE_GIVING');
          newJoinedRoom.teams['2'].players['0'].status = this.$getConst('PLAYER_READY');
          newJoinedRoom.teams['2'].players['1'].status = this.$getConst('PLAYER_READY');

          newJoinedRoom.room.status = this.$getConst('ROOM_PLAYING_GAME');

          newJoinedRoom.teams['1'].status = this.$getConst('TEAM_PLAYING');
          newJoinedRoom.teams['2'].status = this.$getConst('TEAM_WAITING');
          break;

        // is not CCC not on CCC's team
        case 2:
          newJoinedRoom.teams['1'].players['0'].status = this.$getConst('PLAYER_READY');
          newJoinedRoom.teams['1'].players['1'].status = this.$getConst('PLAYER_READY');
          newJoinedRoom.teams['2'].players['0'].status = this.$getConst('PLAYER_CLUE_GIVING');
          newJoinedRoom.teams['2'].players['1'].status = this.$getConst('PLAYER_READY');

          newJoinedRoom.room.status = this.$getConst('ROOM_PLAYING_GAME');

          newJoinedRoom.teams['1'].status = this.$getConst('TEAM_WAITING');
          newJoinedRoom.teams['2'].status = this.$getConst('TEAM_PLAYING');

          break;

        // inbetween round
        case 3:
          newJoinedRoom.room.status = this.$getConst('ROOM_TRANSITION_GAME');
          break;

        // end/win game
        case 4:
          newJoinedRoom.room.status = this.$getConst('ROOM_WIN_GAME');
          newJoinedRoom.teams['1'].score = 0;
          break;
      }

      this.updateJoinedRoom(newJoinedRoom);

    },

    ...mapActions([
      'changeDarkTheme',
      'updateJoinedRoom'
    ]),
    checkDarkTheme: function(payload) {
      if (payload.target.name == "darkmode") {
        if (payload.target.checked == true) {
          this.changeDarkTheme(true);
        }
        else {
          this.changeDarkTheme(false);
        }
      }
    }
  },
  computed: {
    ...mapState ({
      devMode: state => state.settings.devMode,
      isDarkTheme: state => state.settings.isDarkTheme,
      joinedRoom: state => state.game.joinedRoom,
    }),
  }
}

</script>