const state = {
  devMode: false,
  isDarkTheme: true,
  backgroundImg: require("@/assets/img/background/gray/3.png"),
  backgroundColor: {
    hex: '#0a0a0a',
    r: 10,
    g: 10,
    b: 10,
  },
  darkThemeBgColor: '#0a0a0a',
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      hex: hex,
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  },
  reset: {
    bgImg: require("@/assets/img/background/gray/3.png"),
    bgColor: '#0a0a0a',
    darkTheme: true,
  },
}

const actions = {
  changeDarkTheme: ({ commit }, isDarkTheme) => {
    commit('changeDarkTheme', isDarkTheme)
  },
  changeBgImg: ({ commit }, bgImg) => {
    commit('changeBgImg', bgImg)
  },

  // here is where we convert hex to RGB
  changeBgColor: ({ state, commit }, bgColor) => {

    var colorRGB = state.hexToRgb(bgColor);

    console.log(`hex = ${colorRGB.hex}, R = ${colorRGB.r}, G = ${colorRGB.g}, B = ${colorRGB.b}`)

    commit('changeBgColor', colorRGB)

  },
}

const mutations = {
  changeDarkTheme: (state, isDarkTheme) => {
    state.isDarkTheme = isDarkTheme;
  },

  changeBgImg: (state, bgImg) => {
    console.log('passed in bgImg = ',bgImg);
    
    state.backgroundImg = bgImg;
  },

  changeBgColor: (state, bgColor) => {
    state.backgroundColor = bgColor;
  }
}

const getters = {  
}


export default {
  state,
  mutations,
  actions,
  getters
}