import constants from '@/plugins/constants';

export default {
  name: '',
  room: {
    host: '',
    max_players: 4,
    mode: constants['TNT'],
    name: '',
    status: constants['ROOM_PLAYING_GAME'],
    tour_duration: 60,
    win_points: 5,
  },
  teams: {
    1: {
      players: [
        {
          status: constants['PLAYER_CLUE_GIVING'],
          username: 'silver',
        },
        {
          status: constants['PLAYER_READY'],
          username: 'pvcmeyer',
        }
      ],
      score: 5,
      status: constants['TEAM_PLAYING']
    },
    2: {
      players: [
        {
          status: constants['PLAYER_READY'],
          username: 'snolot',
        },
        {
          status: constants['PLAYER_READY'],
          username: 'marco',
        }
      ],
      score: 5,
      status: constants['TEAM_WAITING'],
    },
  },
  timer: 60,
}